<template>
  <div>
    <PageSign
      v-if="data?.contracten?.length"
      title="Rit overeenkomst"
      :api-image-fn="apiImageFn"
      :api-sign-fn="(route) => `${apiSignFn(route)}&rit_id=${rit_id}`"
      :file-name="rit_id"
      v-slot="props"
    >
      <div>
        <UIContract :signature="props.imageSrc" :contract="data?.contracten[0]" :wagens="data?.wagens" @tekenen="props.tekenen"/>
      </div>
    </PageSign>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import UIContract from '@/components/UI/Contract.vue'
import PageSign from '@/pages/signature/sign.vue'
import useGetApi from '@/hooks/useGetApi'

defineProps({
  apiImageFn: Function,
  apiSignFn: Function,
})

const route = useRoute()
const rit_id = computed(() => route.params.rit_id)
const apiData = computed(() => ({ rit_ids: [rit_id.value] }))

const { data } = useGetApi('CONTRACTEN_INFO', apiData, { watch: false })
</script>
