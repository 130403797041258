<template>
  <div class="document-wrapper">
    <template v-if="pdfSrc">
      <a v-if="browser.isIOS || browser.isAndroid" :href="pdfSrc" target="_blank" class="btn info py-2">{{ title }} downloaden</a>
      <iframe v-else-if="browser.isSafari || browser.isIE || browser.isIOS" :src="pdfSrc" v-bind="$attrs" frameborder="1" :title="title" :alt="title"></iframe>
      <object v-else :data="pdfSrc" type="application/pdf" v-bind="$attrs" frameborder="1" :title="title" :alt="title">
        <embed :src="pdfSrc" type="application/pdf" v-bind="$attrs" frameborder="1" :title="title" :alt="title">
      </object>
    </template>
    <div v-else-if="!loading" class="no-data my-12 text-center text-gray-500 font-bold max-w-xs mx-auto" v-bind="$attrs">
      Document niet gevonden
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useHead } from '@vueuse/head'

import useApi from '@/hooks/useApi.js'
import useBrowserDetect from '@/hooks/useBrowserDetect.js'
import { useRoute } from 'vue-router'

const props = defineProps({
  documentDataFn: Function,
  apiTemplateFn: Function,
  apiImageFn: Function,
  // apiSignFn: Function,
  title: String,
})

const api = useApi()
const store = useStore()
const route = useRoute()
const browser = useBrowserDetect()
const DocumentData = props.documentDataFn(route)

const head = ref({
  title: props.title,
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/pdf-lib/dist/pdf-lib.min.js',
      // onload: () => setImmediate(() => init()),
    },
  ],
})

useHead(head)

const pdfSrc = ref(null)
const loading = ref(true)

const init = (n = 0) => {
  if (!loading.value || pdfSrc.value) return
  if (!window.PDFLib && n < 100) {
    setTimeout(() => init(n + 1), 100)
    return
  }

  let user_id = store.state.profile.id
  if (store.getters.hasKantoorPermission) {
    user_id = Math.max(store.state.profile.id, parseInt(route.query.chauffeur || route.query.user_id || user_id, 10))
  }

  let name = String(store.getters.currentUserName || '')
  if (store.getters.hasKantoorPermission) {
      name = String(store.getters.chauffeur(user_id)?.label || '')
  }

  api.get(props.apiImageFn(route), { user_id }, { responseType: 'arraybuffer' }).then(({ data: signatureBuffer }) => {
    if (DocumentData.position && signatureBuffer.byteLength < 1) {
      window.location.href = window.location.pathname.replace('/document', '')
      return
    }
    return api.get(props.apiTemplateFn(route), {}, { responseType: 'arraybuffer' }).then(async ({ data: pdfBuffer }) => {
      const pdfDoc = await window.PDFLib.PDFDocument.load(pdfBuffer)
      pdfDoc.setTitle(props.title, { showInWindowTitleBar: true })
      const pages = pdfDoc.getPages()
      const pageWithsignature = pages[(DocumentData.page || pages.length) - 1]

      // Put signature image in
      if (DocumentData.position) {
        const pngImage = await pdfDoc.embedPng(signatureBuffer)
        const pngDims = pngImage.scale(0.9)
        pageWithsignature.drawImage(pngImage, {
          x: 60,
          y: 60,
          width: pngDims.width,
          height: pngDims.height,
          ...(DocumentData.position || {}),
        })
      }

      // Put name in as text
      if (user_id > 0 && DocumentData.positionName) {
        pageWithsignature.drawText(name.toUpperCase(), {
          x: 30,
          y: 30,
          size: 12,
          // color: '#222222',
          // rotate: 0,
          ...(DocumentData.positionName || {}),
        })
      }

      pdfSrc.value = await pdfDoc.saveAsBase64({ dataUri: true })
      return pdfSrc.value
    })
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => init())
</script>

<style scoped>
.document-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}
iframe, embed, object {
  background: #222;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
}
</style>
