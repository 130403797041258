<template>
  <div>
    <div class="klant-contract">
      <h1>Modelovereenkomst voor diensten voor het verhuur van voertuigen met bestuurder</h1>

      <table>
        <tbody>
          <tr>
            <td class="my-4">
              AALIEBRA BV<br>
              Plankenstraat 130<br>
              1701 Itterbeek<br>
              BTW: BE 0845 242 261
            </td>
            <td class="text-right py-3 flex flex-col gap-1">
              <div>
                <span style="width: 125px;display: inline-block;">JAAR:</span>
                <span style="width: 52px;display: inline-block;">{{ contract.jaar }}</span>
              </div>
              <div v-if="contract.nummer || forceNumber">
                <span style="width: 125px;display: inline-block;">CONTRACT NR:</span>
                <span style="width: 52px;display: inline-block;">{{ contract.nummer }}</span>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <h2>Overeenkomst voor het verhuren van voertuigen met bestuurder</h2>
              <p class="pt-1">
                De Heer Van Lierde met ondernemingsnummer 0845.242.261 en adres Plankenstraat 130 1701 Dilbeek. De
                vennootschap Aaliebra BV waarvan de maatschappelijke zetel is gevestigd in Dilbeek – Plankenstraat 130
                en hierbij vertegenwoordigd door de heer Van Lierde exploitant van een dienst voor het verhuren van
                voertuigen met bestuurder en houder van een vergunning, uitgereikt door de stad/gemeente Dilbeek op
                25-08-2014 overeenkomstig artikel 41 van het decreet van 20 april 2001 betreffende de organisatie van het
                personenvervoer over de weg, hierna “de verhuurder” te noemen, enerzijds;
              </p>
              <div class="text-center pt-4">
                <span class="underline bold">en</span>
              </div>
              <p class="pt-1"><span class="italic">{{ contract.klant }}</span><br>hierna "de huurder" te noemen, anderzijds;</p>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <p class="mt-1">
                Overwegende dat de verhuurder een dienst voor het verhuren van voertuigen met bestuurder in de zin van
                artikel 2, 5°, van het bovengenoemde decreet exploiteert en dat de huurder een beroep wil doen op de
                dienst die door de verhuurder wordt geëxploiteerd voor verplaatsingen van minstens drie uur, eventueel
                onderbroken;
              </p>
              <p class="uppercase">kwamen het volgende overeen:</p>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <h3>Beschrijving verhuurvoertuig(en)</h3>
              <p class="pb-1">
                De verhuurder stelt één of meerdere voertuig(en) ter beschikking van de huurder die beantwoorden aan de
                volgende omschrijving:
              </p>
              <table class="table-wagens">
                <thead>
                  <tr>
                    <th>Herkenningsteken</th>
                    <th>Type voertuig</th>
                    <th>Merk</th>
                    <th>Nummerplaat</th>
                  </tr>
                </thead>
                <tbody v-if="wagens?.length">
                  <tr class="py-0" v-for="(w, index) in wagens" :key="`${index}${w.nummerplaat}`">
                    <td class="pb-0 pt-1">{{ typeof w.nummerplaat.padStart !== 'undefined' ? String(w.teken).padStart(4, '0') : w.teken }}</td>
                    <td class="pb-0 pt-1">{{ w.model }}</td>
                    <td class="pb-0 pt-1">{{ w.merk }}</td>
                    <td class="pb-0 pt-1">{{ w.nummerplaat }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="page-break">&nbsp;</div>
      <table>
        <tbody>

          <tr>
            <td colspan="2">
              <h3>Duur</h3>
              <p class="pb-1">
                De bovengenoemde voertuigen worden ter beschikking van de huurder gesteld:
              </p>
              <p>
                Voor het geheel van de volgende prestatie(s):
              <ul class="list-prestaties" v-if="contract.prestaties?.length">
                <li v-for="(p, index) in contract.prestaties" :key="`prest-${index}`">
                  Prestatie {{ index + 1 }}: op {{ p.start_dag }}-{{ p.start_maand }}-{{ p.start_jaar }} van
                  {{ p.start_uur }} uur {{ p.start_minuten }} tot
                  {{ p.einde_dag }}-{{ p.einde_maand }}-{{ p.einde_jaar }} {{ p.einde_uur }} uur
                  {{ p.einde_minuten }}
                </li>
              </ul>
              </p>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <p class="py-1 bold mt-2">
                Het totaal van de gepresteerde ritten moet ten minste drie uur bedragen.
              </p>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <h3>Prijs</h3>
              <p class="pb-1">
                De prijs van de prestaties bedraagt <i>{{ contract.prijs }}</i> EUR.
              </p>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <h3>Ondertekening</h3>
              <p class="pb-1">
                De bepalingen van het decreet van 20 april 2001 betreffende de organisatie van het personenvervoer over de
                weg en de uitvoeringsbesluiten ervan, zijn van toepassing.
              </p>
              <p class="py-1">
                Gedaan in Dilbeek. Op {{ contract.dag }}-{{ contract.maand }}-{{ contract.jaar }} (dag) (maand) (jaar), in twee
                originele exemplaren, waarvan elk van de partijen erkent er één ontvangen te hebben. Het origineel of een
                kopie van dit contract moet zich steeds in de voertuigen bevinden die het voorwerp uitmaken van deze
                overeenkomst gedurende de uitvoering ervan.
              </p>
            </td>
          </tr>

          <tr>
            <td class="handtekening-kevin">
              <p>
                De verhuurder,<br><br class="hide-print" />
                <img src="https://dashboard.taxiboeken.be/cdn/pdf_templates/kevin-signed.png" alt="Kevin Van Lierde Handtekening" />
              </p>
            </td>
            <td class="handtekening-klant" :key="signature">
              <p>
                De huurder,<br><br class="hide-print" />
                <img v-if="signature" id="klant-signed" :src="`${signature}#test.png`" :alt="`${contract.klant} Handtekening`">
                <button v-if="!hideActions" class="btn success hide-print mt-4" @click="handleTekenen">{{ signature ? 'Opnieuw ' : 'Nu ' }} tekenen</button>
              </p>
            </td>
          </tr>

        </tbody>
      </table>
      <div class="page-break">&nbsp;</div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  contract: {
    type: Object,
    required: true,
    default() { return {} },
  },
  wagens: Array,
  signature: String,
  forceNumber: Boolean,
  print: Boolean,
})

const route = useRoute()
const emit = defineEmits(['tekenen'])

const hideActions = computed(() => !!(route.hash.print || route.query.print || props.print || false))

const handleTekenen = () => emit('tekenen')
const wagens = computed(() => (props.wagens || []).filter(el => el.teken > 0 && !!el.model && !!el.merk && !!el.nummerplaat))
</script>

<style lang="scss" scoped>
@import './../../assets/styles-contract.scss'
</style>
